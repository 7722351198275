import React, { useEffect, useRef, useState } from "react";
import classes from "./Contact.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "./Ui/Button";
import { FaDribbble, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { BsMedium, BsWhatsapp, BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import BgAnimated from "./Ui/BgAnimated";
import head1 from "../assets/My head.svg"
import head2 from "../assets/head2.svg"
import head3 from "../assets/head3.svg"
import head3bg from "../assets/head3bg.svg"
import head4 from "../assets/head4.svg"
import BigBangAnimation from "./Ui/BigBangAnimation";
import logoGreen from "../assets/logoGreen.svg"
import logo from "../assets/footerImg.svg"


function Contact({setActive,phase,setPhase}) {
  const innerContainerRef = useRef(null);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const layers = document.querySelectorAll('.layers');
  const navigate = useNavigate();
  useEffect(() => {
    if(layers && window.innerWidth > 1024) {
      const handleMouseMove = (e) => {
        const speed = 10; 
        const x = (window.innerWidth - e.pageX * speed) / 500;
        const y = (window.innerHeight - e.pageY * speed) / 500;
        setMousePosition({ x, y });
          layers?.forEach((layer) => {
            layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
          })
      
      };
      document.addEventListener("mousemove", handleMouseMove);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [layers]);
    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          navigate('/')
        }
      };
    
      useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
       
        return () => {
          window.removeEventListener("keydown", handleKeyDown);

        };
      }, []);

      useEffect(() => {
        if (innerContainerRef.current) {
          innerContainerRef.current.scrollTo(100, 0)
        }
      }, []);
      
   
  return (
    <div className={classes.container} >
      <div className={classes.head} >
        <Link to={"/"} onClick={() => {setPhase(0) ; navigate('/')}} onMouseEnter={() => {setActive(true)}} onMouseLeave={() => {setActive(false)}}>
            <img loading='lazy' src={logoGreen} alt="logo" />
            Yazan Obeidat
        </Link>
        <Button onClick={() =>  navigate(-1)} onMouseEnter={() => {setActive(true)}} onMouseLeave={() => {setActive(false)}}><IoCloseSharp/> ESC</Button>
        <button className={classes.mobileBtn} onClick={() =>  navigate(-1)}><IoCloseSharp/></button>
      </div>
      <BgAnimated/>
      <BgAnimated/>

      <div className={classes.innerContainer}>
        <div className={classes.left}>
          <p>Let’s be honest</p>
          <h2>
            No one uses <span>contact</span> <br /> forms any<span>m</span>or<span>e</span>
          </h2>
          <div
            className={classes.icons}
            onMouseEnter={() => {
              setActive(true);
            }}
            onMouseLeave={() => {
              setActive(false);
            }}
          >
            <Link to="https://dribbble.com/Yazan_Obeidat">
              <FaDribbble />
            </Link>
            <Link to="https://medium.com/@yazanbaselobeidat">
              <BsMedium />{" "}
            </Link>
            <Link to="https://www.linkedin.com/in/yazan-obeidat-b079a5a7/">
              <FaLinkedinIn />
            </Link>
            <Link to="https://www.instagram.com/yazexperience/?hl=en">
              <FaInstagram />{" "}
            </Link>
          </div>
          <div
            className={classes.social}
            onMouseEnter={() => {
              setActive(true);
            }}
            onMouseLeave={() => {
              setActive(false);
            }}
          >
            <Link to="mailto:yazangraphics@gmail.com">
              <IoMdMail /> yazangraphics@gmail.com{" "}
            </Link>
            <Link target="_blank" to="https://wa.me/+962790791189">
              <BsWhatsapp /> +(962) 79 079 1189
            </Link>
            <Link to="tel:+971585951277">
              <BsFillTelephoneFill /> +(971) 58 595 1277{" "}
            </Link>
          </div>
        <img loading='lazy' className={classes.mini} src={logo} alt="branding" />

        </div>
        <div className={classes.right}>
            {phase === 0 && (
              <div data-speed="5" className="layers">

                <img loading='lazy' src={head1} alt="Yazan Obeidat" />
              </div>
            )}
            {phase === 1 && (
              <div data-speed="5" className="layers">

                <img loading='lazy' src={head2} alt="Yazan Obeidat" />
              </div>
            )}
            {phase === 2 && (
             <div className={classes.phaseThree}>
             <img loading='lazy' src={head3} alt="Yazan Obeidat" />
             <img loading='lazy' src={head3bg} className={classes.phaseThreeBg} alt="Yazan Obeidat" />
             </div>
            )}
            {phase === 3 && (
             <div data-speed="5" className="layers">
               

             <BigBangAnimation/>
             

             <img loading='lazy' src={head4} alt="Yazan Obeidat" />
             </div>
            )}
         
            <img loading='lazy' className={classes.rightLogo} src={logo} alt="branding" />
        </div>
        <div className={classes.lefttwo}>
          <p>Let’s be honest</p>
          <h2>
            No one uses <span>contact</span> <br /> forms any<span>m</span>or<span>e</span>
          </h2>
          <div
            className={classes.icons}
            onMouseEnter={() => {
              setActive(true);
            }}
            onMouseLeave={() => {
              setActive(false);
            }}
          >
            <Link to="https://dribbble.com/Yazan_Obeidat">
              <FaDribbble />
            </Link>
            <Link to="https://medium.com/@yazanbaselobeidat">
              <BsMedium />{" "}
            </Link>
            <Link to="https://www.linkedin.com/in/yazan-obeidat-b079a5a7/">
              <FaLinkedinIn />
            </Link>
            <Link to="https://www.instagram.com/yazexperience/?hl=en">
              <FaInstagram />{" "}
            </Link>
          </div>
          <div
            className={classes.social}
            onMouseEnter={() => {
              setActive(true);
            }}
            onMouseLeave={() => {
              setActive(false);
            }}
          >
            <Link to="mailto:yazangraphics@gmail.com">
              <IoMdMail /> yazangraphics@gmail.com{" "}
            </Link>
            <Link target="_blank" to="https://wa.me/+962790791189">
              <BsWhatsapp /> +(962) 79 079 1189
            </Link>
            <Link to="tel:+971585951277">
              <BsFillTelephoneFill /> +(971) 58 595 1277{" "}
            </Link>
          </div>
        <img loading='lazy' className={classes.mini} src={logo} alt="branding" />

        </div>
      </div>
    </div>
  );
}

export default Contact;
