import { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import BrowserRouter
import Loader from "./components/Ui/Loader";
import Error from "./components/Error";
import MainLayout from "./components/Layouts/MainLayout";
import DefaultCursor from "./components/Ui/DefaultCursor";
import ScrollToTop from "./components/Ui/ScrollToTop";
import Contact from "./components/Contact";

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        resolve(component);
      })
      .catch((error) => {
        console.log(error);
        window.location.reload();
        reject(error);
      });
  });
};

const Loading = <Loader />;
const Details = lazy(() => lazyRetry(() => import("./Pages/Details")));
const Home = lazy(() => lazyRetry(() => import("./Pages/Home")));

const App = () => {
  const [active, setActive] = useState(false);
  const [caseStudy, setCaseStudy] = useState(false);
  const [phase,setPhase] = useState(0);
  const [openContact,setOpenContact] = useState(false);
  const [loads,setLoads] = useState(true);
  useEffect(() => {
    setLoads(true);
    const timer = setTimeout(() => {
      setLoads(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []); 
  if(loads) {
    return <Loader />
  }

  
  return (
    <div>
      <DefaultCursor active={active} caseStudy={caseStudy} />
      <ScrollToTop setActive={setActive}/>
      <Router>
        <Routes>
          <Route path=""  element={<MainLayout openContact={openContact} setOpenContact={setOpenContact} phase={phase} setActive={setActive} setPhase={setPhase} />}>
            <Route
              index
              element={
                <Suspense fallback={Loading}>
                  <Home openContact={openContact} phase={phase} setPhase={setPhase} setActive={setActive} setCaseStudy={setCaseStudy} />
                </Suspense>
              }
            />
            <Route
              path="case-study/:id"
              element={
                <Suspense fallback={Loading}>
                  <Details setLoads={setLoads} openContact={openContact} setActive={setActive} setCaseStudy={setCaseStudy}  />
                </Suspense>
              }
            />
            <Route
              path="contact"
              element={
                <Suspense fallback={Loading}>
                  <Contact setPhase={setPhase} phase={phase} setOpenContact={setOpenContact}  openContact={openContact} setActive={setActive}  />
                </Suspense>
              }
            />
          </Route>
          <Route path="*" element={<Error  setActive={setActive} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
