import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const BASE_URL = 'https://api.yazanobeidat.com'

export const getDribbleWorks = createAsyncThunk(
  "main/dribble",
  async (h) => {
    const config = {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    try {
      const response = await axios.get(`${BASE_URL}/dribble-works/`, config);
      return response.data;
    } catch (error) {
      console.log("error in redux", error);
    }
  }
);
export const getCaseStudy = createAsyncThunk(
  "main/casestudy",
  async (h) => {
    const config = {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    try {
      const response = await axios.get(`${BASE_URL}/case-studies/`, config);
      return response.data;
    } catch (error) {
      console.log("error in redux", error);
    }
  }
);
export const getCaseStudyById = createAsyncThunk(
  "main/casestudybyid",
  async (id) => {
    const config = {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    try {
      const response = await axios.get(`${BASE_URL}/case-study/${id}/`, config);
      return response.data;
    } catch (error) {
      console.log("error in redux", error);
    }
  }
);

const initialState = {
  dribble: [],
  dribbleLoading:false,
  dribbleError:false,

  caseStudy:[],
  caseStudyLoading:false,
  caseStudyError:false,

  caseStudyById:[],
  caseStudyByIdLoading:false,
  caseStudyByIdError:false,


 
};
export const main = createSlice({
  name: "main",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get dribble
    builder.addCase(getDribbleWorks.pending, (state, action) => {
      state.dribbleLoading = true;
      state.dribbleError = false;
    });
    builder.addCase(getDribbleWorks.fulfilled, (state, action) => {
      state.dribbleLoading = false;
      state.dribbleError = false;
      state.dribble = action.payload;
    });
    builder.addCase(getDribbleWorks.rejected, (state, action) => {
      state.dribbleLoading = false;
      state.dribbleError = {
        status: true,
        data: action.payload,
      };
    });
    // get Case Study
    builder.addCase(getCaseStudy.pending, (state, action) => {
      state.caseStudyLoading = true;
      state.caseStudyError = false;
    });
    builder.addCase(getCaseStudy.fulfilled, (state, action) => {
      state.caseStudyLoading = false;
      state.caseStudyError = false;
      state.caseStudy = action.payload;
    });
    builder.addCase(getCaseStudy.rejected, (state, action) => {
      state.caseStudyLoading = false;
      state.caseStudyError = {
        status: true,
        data: action.payload,
      };
    });
    // get Case Study By Id
    builder.addCase(getCaseStudyById.pending, (state, action) => {
      state.caseStudyByIdLoading = true;
      state.caseStudyByIdError = false;
    });
    builder.addCase(getCaseStudyById.fulfilled, (state, action) => {
      state.caseStudyByIdLoading = false;
      state.caseStudyByIdError = false;
      state.caseStudyById = action.payload;
    });
    builder.addCase(getCaseStudyById.rejected, (state, action) => {
      state.caseStudyByIdLoading = false;
      state.caseStudyByIdError = {
        status: true,
        data: action.payload,
      };
    });
  },
});

export const mainReducer = main.reducer;
