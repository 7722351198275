import React from "react";
import classes from "./Button.module.scss";

function Button({ onClick, children, loading, disabled,onMouseLeave,onMouseEnter }) {

  return (
    <button
      className={classes.glowonhover}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      onMouseEnter={onMouseEnter} 
      onMouseLeave={onMouseLeave}
   
    >
      {children}
    </button>
  );
}

export default Button;
