import React, { useRef, useEffect } from "react";
const Vector = {
    x: 0,
    y: 0,
    getX: function () {
      return this.x;
    },
    getY: function () {
      return this.y;
    },
    setX: function (x) {
      this.x = x;
    },
    setY: function (y) {
      this.y = y;
    },
    setAngle: function (angle) {
      var length = this.getLength();
      this.x = Math.cos(angle) * length;
      this.y = Math.sin(angle) * length;
    },
    setLength: function (length) {
      var angle = this.getAngle();
      this.x = Math.cos(angle) * length;
      this.y = Math.sin(angle) * length;
    },
    getAngle: function () {
      return Math.atan2(this.y, this.x);
    },
    getLength: function () {
      return Math.sqrt(this.x * this.x + this.y * this.y);
    },
    create: function (x, y) {
      var obj = Object.create(this);
  
      obj.setX(x);
      obj.setY(y);
  
      return obj;
    },
    add: function (v2) {
      var x = this.x + v2.x;
      var y = this.y + v2.y;
  
      return this.create(x, y);
    },
    addTo: function (v2) {
      v2.x += this.getX();
      v2.y += this.getY();
    },
    subtract: function (v2) {
      var x = this.x - v2.x,
        y = this.y - v2.y;
  
      var o = this.create(x, y);
      return o;
    },
    subtractFrom: function (v2) {
      v2.setX(v2.getX() - this.getX());
      v2.setY(v2.getY() - this.getY());
    },
    multiply: function (val) {
      this.x *= val;
      this.y *= val;
    },
  };
  
  const Particle = {
    position: null,
    velocity: null,
    create: function (x, y, speed, angle, grav) {
      var obj = Object.create(this);
  
      obj.position = Vector.create(x, y);
      obj.velocity = Vector.create(0, 0);
  
      obj.velocity.setLength(speed);
      obj.velocity.setAngle(angle);
  
      return obj;
    },
    update: function () {
      this.velocity.addTo(this.position);
    },
  };
function BigBangAnimation() {
    
    const canvasRef = useRef(null);
    const w = window.innerWidth;
    const h = window.innerHeight;
    const numParticles = 2000;
    const particles = [];
  
    useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
  
      for (let i = 0; i < numParticles; i++) {
        particles.push(Particle.create(w / 2, h / 2, Math.random() * 10, Math.random() * 2 * Math.PI));
        // particles.push(Particle.create(w / 2, h / 2, 5, Math.random() * 2 * Math.PI));
      }
  
      function update() {
        context.clearRect(0, 0, w, h);
      
        let i;
        for (i = 0; i < numParticles / 3; i++) {
          const size = 4; // Set the size of the square
          context.fillStyle = 'white'; // Set the fill color to white
          context.fillRect(
            particles[i].position.getX() - size / 2,
            particles[i].position.getY() - size / 2,
            size,
            size
          );
      
          particles[i].update();
        }
      
        for (i; i < (2 * numParticles) / 3; i++) {
          const size = 2; // Set the size of the square
          context.fillStyle = 'white'; // Set the fill color to white
          context.fillRect(
            particles[i].position.getX() - size / 2,
            particles[i].position.getY() - size / 2,
            size,
            size
          );
      
          particles[i].update();
        }
      
        for (i; i < numParticles; i++) {
          const size = 3; 
          context.fillStyle = 'white';
          context.fillRect(
            particles[i].position.getX() - size / 2,
            particles[i].position.getY() - size / 2,
            size,
            size
          );
      
          particles[i].update();
        }
      
        window.requestAnimationFrame(update);
      }
  
      update();
    }, [w, h]);


    return (
      <canvas style={{position:'absolute',zIndex:-9,left:"50%",top:"50%",transform:'translate(-50%,-50%)'}} ref={canvasRef} width={w} height={h}></canvas>
    );
}

export default BigBangAnimation;
