import React from 'react'
import classes from "./Footer.module.scss"
import { Link } from 'react-router-dom'
import { FaDribbble, FaInstagram, FaLinkedinIn } from 'react-icons/fa'
import { BsMedium } from 'react-icons/bs'
import BlackHole from './Ui/BlackHole'

function Footer({setActive}) {
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
      <div className={classes.left}>
        <p>Let’s connect</p>
        <h2>
        <span>Designs</span> that reverberate across the <span>cosmos</span>
        </h2>
        <div className={classes.icons}  onMouseEnter={() => {setActive(true)}} onMouseLeave={() => {setActive(false)}}>
            <Link  to="https://dribbble.com/Yazan_Obeidat"><FaDribbble/> </Link>
            <Link to="https://medium.com/@yazanbaselobeidat"><BsMedium/> </Link>
            <Link to="https://www.linkedin.com/in/yazan-obeidat-b079a5a7/"><FaLinkedinIn/></Link>
            <Link to="https://www.instagram.com/yazexperience/?hl=en"><FaInstagram/> </Link>
        </div>

      </div>
        <BlackHole setActive={setActive}/>
     
      </div>
    <div className={classes.copy}>
    © Yazan Obeidat. All rights reserved
    </div>
    </div>
  )
}

export default Footer