import React, { useEffect, useState } from "react";
import classes from "./Navbar.module.scss";
import logoGreen from "../assets/logoGreen.svg";
import Button from "./Ui/Button";
import { Link, useNavigate } from "react-router-dom";
import icon from '../assets/navIcon.svg'
function NavBar({setActive,setPhase,setOpenContact}) {
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={classes.navbar}
      style={{
        backgroundColor: scrolled && "rgba(0,0,0,0.1)",
        backdropFilter: scrolled && "blur(25px)",
        padding: scrolled && "1rem 6rem",
      }}
    >
      <Link to={"/"} onClick={() => setPhase(0)} onMouseEnter={() => {setActive(true)}} onMouseLeave={() => {setActive(false)}}>
        <img loading='lazy' src={logoGreen} alt="logo" />
        <p>Yazan Obeidat</p>
      </Link>
      <Button onClick={() => navigate('/contact')} onMouseEnter={() => {setActive(true)}} onMouseLeave={() => {setActive(false)}}  >
      Contact
      </Button>
      <img loading='lazy' className={classes.icon} src={icon} onClick={() => navigate('/contact')} onMouseEnter={() => {setActive(true)}} onMouseLeave={() => {setActive(false)}} alt="icon" />
    </div>
  );
}

export default NavBar;
