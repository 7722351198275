import React from 'react'
import classes from "./BgAnimated.module.scss"
function BgAnimated() {
  return (
    <div id="particle-container">
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
	<div className={classes.particle}></div>
</div>
  )
}

export default BgAnimated