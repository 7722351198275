import React from "react";
import classes from "./DefaultCursor.module.scss";
import useMousePosition from "../CustomHooks/useMousePosition";

function DefaultCursor({ active, caseStudy }) {
  const { x, y } = useMousePosition();

  return (
    <>
      <div
        style={{
          left: `${x}px`,
          top: `${y}px`,
          width: active ? "25px" : caseStudy ? "190px" : "140px",
          height: active ? "25px" : caseStudy ? "190px" : "140px",
          border: active
            ? "5px solid rgba(255, 255, 255, 1)"
            : "1px solid  rgba(144, 159, 186, 1)",
        }}
        className={classes.ring}
      >
        {caseStudy && (
          <p className={classes.caseText}>
            View case <br /> study
          </p>
        )}
      </div>
    </>
  );
}

export default DefaultCursor;
