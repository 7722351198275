import React, { useEffect, useState } from 'react'
import './ScrollToTop.scss'
function ScrollToTop({setActive}) {
    const [scrollProgress, setScrollProgress] = useState(0);

    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight - windowHeight;
      const scrollY = window.scrollY;
      const progress = (scrollY / documentHeight) * 100;
      setScrollProgress(progress);
    };
  
    useEffect(() => {
      const progressPath = document.querySelector('.progress-wrap path');
      const pathLength = progressPath.getTotalLength();
      progressPath.style.transition = 'none';
      progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
      progressPath.style.strokeDashoffset = pathLength - (pathLength * scrollProgress) / 100;
      progressPath.getBoundingClientRect();
      progressPath.style.transition = 'stroke-dashoffset 10ms linear';
  
      const scrollListener = () => {
        handleScroll();
      };
  
      window.addEventListener('scroll', scrollListener);
  
      return () => {
        window.removeEventListener('scroll', scrollListener);
      };
    }, [scrollProgress]);
  
    const scrollToTop = () => {
      const offset = 50;
      const duration = 550;
      if (window.pageYOffset > offset) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };
  
    const progressWrapClass = `progress-wrap ${scrollProgress > 0 ? 'active-progress' : ''}`;
  
  
    return (
        <div className={progressWrapClass} onClick={scrollToTop}  onMouseEnter={() => {
            setActive(true);
          }}
          onMouseLeave={() => {
            setActive(false);
          }}>
          <svg className="progress-circle" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style={{ strokeDashoffset: 100 - scrollProgress }} />
          </svg>
          
        </div>
    );
  };

export default ScrollToTop