import React from "react";
import "./Loader.scss";
import logo from "../../assets/logoloader.svg";
import logoGreen from "../../assets/logoGreen.svg";
function Loader({details}) {
  return (
    <div className="loader">
      <div className="logocontainer">
        <img loading='lazy' src={logoGreen} alt="logo" />
        <p>Yazan Obeidat</p>
      </div>
      {!details && <>
        <div className="loading">
        <img loading='lazy' src={logo} alt="logo" />
        <div>
        <svg width="0" height="0">
          <filter  id="gooey-plasma-2">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="20"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 50 -16"
              result="goo"
            />
            <feTurbulence baseFrequency="0.03" numOctaves="1" />

            <feDisplacementMap
              id="displacement"
              in="blur"
              scale="50"
              xChannelSelector="B"
              yChannelSelector="G"
            />

            <feBlend id="blend-mode" in="gooey" mode="overlay" />
          </filter>
        </svg>
        <div className="plasma-2">
          <ul className="gooey-container">
            <li className="bubble"></li>
            <li className="bubble"></li>
            <li className="bubble"></li>
            <li className="bubble"></li>
            <li className="bubble"></li>
            <li className="bubble"></li>
          </ul>
        </div>
      </div>
        </div>
  
      
      <h2>
        This is beyond your <br /> experience
      </h2>
      </>}
   

    </div>
  );
}

export default Loader;
