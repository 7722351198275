import React from 'react'
import NavBar from '../NavBar'
import { Outlet } from 'react-router-dom'
import Footer from '../Footer'
import Contact from '../Contact';

function MainLayout({setActive,setPhase,phase, openContact,setOpenContact}) {
   
 
  return (
    <div>
      <NavBar setPhase={setPhase} setActive={setActive} />
      <Outlet />
      <Footer setActive={setActive} />
    </div>
  )
}

export default MainLayout