import React, { useEffect, useRef } from "react";
import footerImg from "../../assets/footerImg.svg";
import classes from "./BlackHole.module.scss";
import { useNavigate } from "react-router-dom";
class Particle {
  constructor(x = 0, y = 0) {
    this.reset();
    this.fadingSpeed = Math.random();
  }

  update() {
    this.position.x += Math.random() * 2 - 1;
    this.position.y -= this.velocity.y;
    this.alpha -= this.fadingSpeed;

    if (this.alpha < 0) {
      this.reset();
    }
  }

  reset() {
    this.position = { x: 0, y: 0 };
    this.velocity = { x: 0, y: Math.random() - 0.80 };
    this.alpha = 1;
    this.fadingSpeed = Math.random() * 0.03 + 0.005;
  }
}

class ParticleEmitter {
  constructor(x = 0, y = 0) {
    this.position = { x: x, y: y };
    this.particles = [];
    this.particlesNumber = 6;

    for (let i = 0; i < this.particlesNumber; i++) {
      const particle = new Particle();
      this.particles.push(particle);
    }
  }

  update() {
    for (let particle of this.particles) {
      particle.update();
    }
  }
}

const BlackHole = ({setActive, setOpenContact}) => {
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas) {
          return;
      }
      const context = canvas.getContext("2d");
      const particleEmitters = [];
      const radius = 200;

      const resize = () => {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
      };

      const render = () => {
          const centerX = canvas.width / 2;
          const centerY = canvas.height / 2;

          for (let particleEmitter of particleEmitters) {
              particleEmitter.update();

              context.save();
              context.translate(centerX, centerY);
              context.rotate(
                  (particleEmitters.indexOf(particleEmitter) * Math.PI) / 80
              );

              for (let particle of particleEmitter.particles) {
                  particle.update();

                  context.globalAlpha = particle.alpha;
                  context.beginPath();
                  context.arc(
                      particle.position.x,
                      particleEmitter.position.y - particle.position.y,
                      1,
                      0,
                      Math.PI * 2
                  );
                  context.fillStyle = "#ecf0f1";
                  context.fill();
                  context.closePath();
              }
              context.restore();
          }
      };

      const loop = () => {
          const fps = 60;
          const fpsInterval = 1000 / fps;
          let then = Date.now();

          for (let i = 0; i < 160; i++) {
              const particleEmitter = new ParticleEmitter(0, radius);
              particleEmitters.push(particleEmitter);
          }

          const animate = () => {
              requestAnimationFrame(animate);
              const now = Date.now();
              const delta = now - then;

              if (delta > fpsInterval) {
                  context.clearRect(0, 0, canvas.width, canvas.height);
                  render();
                  then = now - (delta % fpsInterval);
              }
          };

          window.addEventListener("resize", resize);
          resize();
          animate();
      };

      loop();

      // Clean up event listeners to prevent memory leaks
      return () => {
          window.removeEventListener('resize', resize);
      };
  }, []);

  return (
      <div className={classes.container}>
          <canvas ref={canvasRef}></canvas>
          <div className={classes.image}>
              <img 
                  src={footerImg} 
                  onClick={() => navigate('/contact')} 
                  onMouseEnter={() => {setActive(true)}} 
                  onMouseLeave={() => {setActive(false)}} 
                  alt="logo"
                  role="button" // ARIA role for accessibility
              />
          </div>
      </div>
  );
};

export default BlackHole;
