import React, { useEffect } from 'react'
import classes from './Error.module.scss'
import Button from './Ui/Button'
import { useNavigate } from 'react-router-dom'
function Error({setActive}) {
  const navigate = useNavigate()
  useEffect(() => {
    setActive(false)
  }, [])
  return (
    <div className={classes.error}>
      <h2>404 - Not Found</h2>
      <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
      <Button onClick={() => navigate('/')} onMouseEnter={() => {setActive(true)}} onMouseLeave={() => {setActive(false)}}>
        Go To Homepage
      </Button>
    </div>
  )
}

export default Error